import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Delete, Get } from "../../../constants/dataAccess";
import userType from "../../../types/userType";

interface optionsType {
    value: string;
    label: string;
}

function DeleteUserForm() {
    const [userId, setUserId] = useState('');
    const [options, setOptions] = useState<optionsType[]>();
    const [lockForm, setLockForm] = useState(false);

    const navigate = useNavigate();

    const getUsers = async () => {
        const optionsvalue: optionsType[] = [];
        const data: userType[] = await Get(`/users`);
        data.forEach((user: userType) => {
            optionsvalue.push({ value: user.accountId, label: user.displayName });
        });
        setOptions(optionsvalue);
    };

    useEffect(() => {
        getUsers();
    }, []);

    const handleForm = async (event: React.SyntheticEvent) => {
        setLockForm(true);
        event.preventDefault();
        // await AsyncStorage.setItem('userInfo', JSON.stringify(userInfo));
        try {
            const deleteUser = await Delete(`/users/${userId}`);
            if (deleteUser === 400 || deleteUser === 500 || deleteUser === 409) {
                throw new Error();
            }
            navigate('/admin');
            const e = new CustomEvent('ShowAlert', {
                detail: {
                    label: 'Gebruiker succesvol verwijderd',
                    type: 'Success',
                },
            });
            window.dispatchEvent(e);
        } catch (error) {
            const e = new CustomEvent('ShowAlert', {
                detail: {
                    label: 'Gebruiker verwijderen is niet gelukt',
                    type: 'Warning',
                },
            });
            window.dispatchEvent(e);
        }
        setLockForm(false);
    }

    return (
        <form id="add-book-form" onSubmit={handleForm}>
            <fieldset className="o-form" disabled={lockForm}>
                <div className="o-form__radio-uitgeleend">
                    <Select
                        onChange={(option) => option && setUserId(option?.value)}
                        options={options}
                        className="o-form__select"
                        classNamePrefix="o-form__select"
                        placeholder="Naam"
                    />
                </div>
            </fieldset>
        </form>
    );
};



export default DeleteUserForm;