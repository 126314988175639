const url = process.env.REACT_APP_BACKEND_URL;

export const Get = async (endpoint: string) => {
  const res = await fetch(`${url}${endpoint}`);
  // const res = await fetch(`${endpoint}`);
  const jsonData = await res.json();
  return jsonData;
};

export const Delete = async (endpoint: string) => {
  const res = await fetch(`${url}${endpoint}`, { method: 'DELETE' });
  // const res = await fetch(`${endpoint}`, { method: 'DELETE' });
  return res.status;
};

export const Post = async (endpoint: string, data: unknown) => {
  const res = await fetch(`${url}${endpoint}`, {
  // const res = await fetch(`${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((r) => r.json())
  ;
  return res;
};


export const Put = async (endpoint: string, data: unknown) => {
  const res = await fetch(`${url}${endpoint}`, {
  // const res = await fetch(`${endpoint}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((r) => r.json());

  return res;
};
