import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Delete } from '../../../constants/dataAccess';
import Button from '../../01_atoms/Button/Button';

interface popUpViewDeleteProps {
  title: string;
  auteur: string;
  id: string;
  setShowPopUp: (showPopUp: boolean) => void;
}

function PopUpViewDelete(props: popUpViewDeleteProps) {
  const { title, auteur, id, setShowPopUp } = props;
  const [clicked, setClicked] = useState('');
  const navigate = useNavigate();

  const handleClicked = async () => {
    if (clicked === 'Ja') {
      const deleteBook = await Delete(`/books/${id}`);
      if (deleteBook === 200) {
        setShowPopUp(false);
        // setDataChanged(true);
        const event = new CustomEvent('ShowAlert', {
          detail: {
            label: 'Boek is succesvol verwijderd',
            type: 'Success',
          },
        });
        navigate('../admin');
        window.dispatchEvent(event);
      } else {
        // Delete failed
        setShowPopUp(false);
        const event = new CustomEvent('ShowAlert', {
          detail: {
            label: 'Boek is niet verwijderd',
            type: 'Warning',
          },
        });
        window.dispatchEvent(event);
      }
    } else {
      // Clicked cancel button
      setShowPopUp(false);
    }
  };

  useEffect(() => {
    if (clicked !== '') {
      handleClicked();
    }
  }, [clicked]);

  return (
    <div className="o-pop-up-modal">
      <div className="o-pop-up-modal__container">
        <p>Weet je zeker dat je boek</p>
        <div className="o-pop-up-modal__text-container">
          <p>{title}</p>
          <p className="o-pop-up-modal__text-author">{auteur}</p>
        </div>
        <p>zult verwijderen uit de bibliotheek</p>
        <div className="o-pop-up-modal__buttons">
          <Button label="Ja" type="primary" setClicked={setClicked} />
          <Button label="Nee" type="secondary" setClicked={setClicked} />
        </div>
      </div>
    </div>
  );
}

export default PopUpViewDelete;
