import React from 'react';
import AdminEditBookTemplate from '../../04_templates/AdminEditBookTemplate/AdminEditBookTemplate';

function AdminEditBookPage() {
  return (
    <main>
      <AdminEditBookTemplate />
    </main>
  );
}

export default AdminEditBookPage;
