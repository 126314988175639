import React, { useEffect, useState } from 'react';

function SearchBar() {
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const event = new CustomEvent('filter', { detail: filter });
    window.dispatchEvent(event);
  }, [filter]);

  return (
    <div className="a-search-bar">
      <input
        type="text"
        placeholder="Zoeken"
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      />

      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.59053 14.5C9.39476 14.5 10.1911 14.3448 10.9341 14.0433C11.6772 13.7418 12.3523 13.2998 12.921 12.7427C13.4896 12.1855 13.9407 11.5241 14.2485 10.7961C14.5563 10.0682 14.7147 9.28795 14.7147 8.50002C14.7147 7.71208 14.5563 6.93187 14.2485 6.20391C13.9407 5.47596 13.4896 4.81453 12.921 4.25737C12.3523 3.70022 11.6772 3.25827 10.9341 2.95674C10.1911 2.65521 9.39476 2.50002 8.59053 2.50002C6.96631 2.50002 5.40861 3.13216 4.26011 4.25737C3.11161 5.38259 2.46639 6.90872 2.46639 8.50002C2.46639 10.0913 3.11161 11.6174 4.26011 12.7427C5.40861 13.8679 6.96631 14.5 8.59053 14.5V14.5ZM15.0413 13.406L18.6954 16.986C18.7928 17.0783 18.8705 17.1887 18.9239 17.3108C18.9773 17.4328 19.0053 17.564 19.0064 17.6968C19.0075 17.8296 18.9816 17.9613 18.9302 18.0841C18.8787 18.207 18.8029 18.3186 18.707 18.4124C18.6111 18.5062 18.4971 18.5804 18.3716 18.6306C18.2461 18.6808 18.1117 18.706 17.9762 18.7048C17.8406 18.7035 17.7067 18.6758 17.5822 18.6233C17.4577 18.5708 17.3452 18.4946 17.2511 18.399L13.597 14.819C11.9562 16.0669 9.8917 16.6552 7.82374 16.4642C5.75578 16.2732 3.83984 15.3173 2.46593 13.7911C1.09203 12.2648 0.36345 10.283 0.42851 8.24894C0.49357 6.21492 1.34738 4.28164 2.81613 2.84266C4.28488 1.40368 6.25815 0.567182 8.33426 0.503441C10.4104 0.4397 12.4332 1.15351 13.9911 2.49957C15.5489 3.84562 16.5246 5.72273 16.7195 7.74877C16.9144 9.77481 16.3139 11.7975 15.0403 13.405L15.0413 13.406Z"
          fill="#D6D6D6"
        />
      </svg>
    </div>
  );
}

export default SearchBar;
