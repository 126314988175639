import React from 'react';

type ButtonProps = {
  label: string;
  type: 'primary' | 'secondary' | 'primary-monochrome';
  submit?: boolean;
  submitForm?: string;
  setClicked: (clicked: string) => void;
};

function Button(props: ButtonProps) {
  let cssClass = 'a-button';
  const { label, type, setClicked, submit, submitForm } = props;

  if (type === 'primary') {
    cssClass += ' a-button__primary';
  }
  if (type === 'primary-monochrome') {
    cssClass += ' a-button__primary-monochrome';
  }
  if (type === 'secondary') {
    cssClass += ' a-button__secondary';
  }

  return (
    <button
      type={submit ? 'submit' : 'button'}
      form={submit ? submitForm : ''}
      className={cssClass}
      onClick={() => setClicked(label)}
    >
      {label}
    </button>
  );
}

Button.defaultProps = {
  submit: false,
  submitForm: '',
};

export default Button;
