import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BookOverview from '../../../types/BookOverviewType';
import Header from '../../02_molecules/Header/Header';
import SubtitleBookOverview from '../../03_organisms/SubtitleBookOverview/SubtitleBookOverview';

interface adminOverviewProps {
  data?: BookOverview[];
}

function AdminOverviewTemplate(props: adminOverviewProps) {
  const { data } = props;

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Sweet Bibi - Admin Overzicht';
  }, []);

  return (
    <div>
      <header>
        <Header
          title="Sweet Bibi - Admin"
          searchBar
          buttonLabel="Boek toevoegen"
          buttonAction={() => navigate('/admin/add')}
          buttonLabel2="Gebruiker toevoegen"
          buttonAction2={() => navigate('/admin/adduser')}
          buttonLabel3="Gebruiker verwijderen"
          buttonAction3={() => navigate('/admin/deleteuser')}
        />
      </header>
      <SubtitleBookOverview subtitle="Uitgeleend" books={data} admin />
      <SubtitleBookOverview subtitle="Beschikbaar" books={data} admin />
    </div>
  );
}

AdminOverviewTemplate.defaultProps = {
  data: [],
};

export default AdminOverviewTemplate;
