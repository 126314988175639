import React from 'react';

type ReaderBannerProps = {
  displayName: string;
};

function ReaderBanner(props: ReaderBannerProps) {
  const { displayName } = props;

  // First name is last word in DisplayName
  const firstNameLetter = `${displayName.split(' ').pop()} `;
  const lastName = displayName.substring(0, displayName.lastIndexOf(' '));
  const fullName = firstNameLetter + lastName;

  return (
    <div className="a-reader-banner__container">
      <span className="a-reader-banner">{fullName}</span>
      {/* <span className="a-reader-banner__hover">{fullName}</span> */}
    </div>
  );
}
export default ReaderBanner;
