import React from 'react';
import { AlertType } from '../../../types/AlertTypes';

type AlertProps = {
  label: string;
  type: AlertType;
};

function Alert(props: AlertProps) {
  const { label, type } = props;

  let cssClass = 'a-alert';
  let cssClassBorderLeft = 'a-alert__border-left';
  let cssClassButton = 'a-alert__button';
  let cssButtonIcon = 'a-alert__button-icon';

  if (type === 'Success') {
    cssClass += ' a-alert__success';
    cssClassBorderLeft += ' a-alert__border-left__success';
    cssClassButton += ' a-alert__button__success';
    cssButtonIcon += ' a-alert__button-icon__success';
  }
  if (type === 'Warning') {
    cssClass += ' a-alert__warning';
    cssClassBorderLeft += ' a-alert__border-left__warning';
    cssClassButton += ' a-alert__button__warning';
    cssButtonIcon += ' a-alert__button-icon__warning';
  }

  function closeAlert() {
    const event = new CustomEvent('CloseAlert', {
      detail: {
        showAlert: false,
      },
    });
    window.dispatchEvent(event);
  }

  return (
    <div className={cssClass}>
      <div className={cssClassBorderLeft} />
      {type === 'Success' ? (
        <svg
          className="a-alert__icon"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="11" fill="#00A779" />
          <path
            d="M10.4862 16.7299C10.355 16.7302 10.2249 16.7045 10.1036 16.6544C9.98229 16.6043 9.87205 16.5307 9.77923 16.4379L5.53723 12.1949C5.44166 12.1027 5.3654 11.9924 5.31291 11.8705C5.26041 11.7485 5.23274 11.6173 5.23149 11.4845C5.23024 11.3517 5.25545 11.22 5.30565 11.0971C5.35584 10.9742 5.43001 10.8625 5.52384 10.7685C5.61766 10.6746 5.72926 10.6002 5.85212 10.5499C5.97498 10.4995 6.10664 10.4741 6.23942 10.4752C6.3722 10.4762 6.50344 10.5037 6.62548 10.556C6.74752 10.6083 6.85792 10.6845 6.95023 10.7799L10.4852 14.3149L16.8502 7.95189C17.0377 7.76425 17.2921 7.65879 17.5574 7.65869C17.8226 7.6586 18.0771 7.76389 18.2647 7.95139C18.4524 8.1389 18.5578 8.39327 18.5579 8.65854C18.558 8.92381 18.4527 9.17825 18.2652 9.36589L11.1932 16.4379C11.1004 16.5307 10.9902 16.6043 10.8688 16.6544C10.7475 16.7045 10.6175 16.7302 10.4862 16.7299V16.7299Z"
            fill="#54E0BA"
          />
        </svg>
      ) : null}
      {type === 'Warning' ? (
        <svg
          className="a-alert__icon"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8 1.6131L19.501 12.7741C20.464 14.3771 19.991 16.4861 18.444 17.4841C17.9248 17.8203 17.3196 17.9995 16.701 18.0001H3.298C1.477 18.0001 0 16.4701 0 14.5811C0 13.9421 0.173 13.3171 0.498 12.7741L7.2 1.6131C8.162 0.0101025 10.196 -0.480898 11.743 0.517102C12.171 0.793102 12.533 1.1681 12.8 1.6131ZM10 14.0001C10.2652 14.0001 10.5196 13.8947 10.7071 13.7072C10.8946 13.5197 11 13.2653 11 13.0001C11 12.7349 10.8946 12.4805 10.7071 12.293C10.5196 12.1055 10.2652 12.0001 10 12.0001C9.73478 12.0001 9.48043 12.1055 9.29289 12.293C9.10536 12.4805 9 12.7349 9 13.0001C9 13.2653 9.10536 13.5197 9.29289 13.7072C9.48043 13.8947 9.73478 14.0001 10 14.0001V14.0001ZM10 5.0001C9.73478 5.0001 9.48043 5.10546 9.29289 5.293C9.10536 5.48053 9 5.73489 9 6.0001V10.0001C9 10.2653 9.10536 10.5197 9.29289 10.7072C9.48043 10.8947 9.73478 11.0001 10 11.0001C10.2652 11.0001 10.5196 10.8947 10.7071 10.7072C10.8946 10.5197 11 10.2653 11 10.0001V6.0001C11 5.73489 10.8946 5.48053 10.7071 5.293C10.5196 5.10546 10.2652 5.0001 10 5.0001Z"
            fill="#E27101"
          />
        </svg>
      ) : null}

      <span className="a-alert__label">{label}</span>
      <button type="button" className={cssClassButton} onClick={closeAlert}>
        <svg
          className={cssButtonIcon}
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.31382 5.90006L10.8488 2.36406C10.9443 2.27182 11.0205 2.16147 11.0729 2.03947C11.1253 1.91746 11.1529 1.78624 11.1541 1.65346C11.1552 1.52069 11.1299 1.38901 11.0796 1.26611C11.0294 1.14321 10.9551 1.03156 10.8612 0.937669C10.7673 0.843776 10.6557 0.769523 10.5328 0.719242C10.4099 0.668961 10.2782 0.643659 10.1454 0.644813C10.0126 0.645967 9.88142 0.673553 9.75942 0.725962C9.63741 0.778371 9.52707 0.854553 9.43482 0.950064L5.89882 4.48506L2.36382 0.950064C2.27157 0.854553 2.16123 0.778371 2.03922 0.725962C1.91722 0.673553 1.786 0.645967 1.65322 0.644813C1.52044 0.643659 1.38876 0.668961 1.26587 0.719242C1.14297 0.769523 1.03132 0.843776 0.937425 0.937669C0.843532 1.03156 0.769279 1.14321 0.718998 1.26611C0.668717 1.38901 0.643415 1.52069 0.644569 1.65346C0.645723 1.78624 0.673309 1.91746 0.725718 2.03947C0.778127 2.16147 0.854309 2.27182 0.949819 2.36406L4.48482 5.89906L0.949819 9.43506C0.854309 9.52731 0.778127 9.63766 0.725718 9.75966C0.673309 9.88166 0.645723 10.0129 0.644569 10.1457C0.643415 10.2784 0.668717 10.4101 0.718998 10.533C0.769279 10.6559 0.843532 10.7676 0.937425 10.8615C1.03132 10.9554 1.14297 11.0296 1.26587 11.0799C1.38876 11.1302 1.52044 11.1555 1.65322 11.1543C1.786 11.1532 1.91722 11.1256 2.03922 11.0732C2.16123 11.0208 2.27157 10.9446 2.36382 10.8491L5.89882 7.31406L9.43482 10.8491C9.52707 10.9446 9.63741 11.0208 9.75942 11.0732C9.88142 11.1256 10.0126 11.1532 10.1454 11.1543C10.2782 11.1555 10.4099 11.1302 10.5328 11.0799C10.6557 11.0296 10.7673 10.9554 10.8612 10.8615C10.9551 10.7676 11.0294 10.6559 11.0796 10.533C11.1299 10.4101 11.1552 10.2784 11.1541 10.1457C11.1529 10.0129 11.1253 9.88166 11.0729 9.75966C11.0205 9.63766 10.9443 9.52731 10.8488 9.43506L7.31382 5.89906V5.90006Z" />
        </svg>
      </button>
    </div>
  );
}

export default Alert;
