import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../01_atoms/Button/Button';
import Icon from '../../01_atoms/Icon/Icon';
import SearchBar from '../../01_atoms/SearchBar/SearchBar';

interface titleProps {
  title: string;
  searchBar?: boolean;
  buttonLabel?: string;
  buttonAction?: (clicked: string) => void | undefined;
  buttonLabel2?: string;
  buttonAction2?: (clicked: string) => void | undefined;
  buttonLabel3?: string;
  buttonAction3?: (clicked: string) => void | undefined;
}

function Header(props: titleProps) {
  const { title, searchBar, buttonLabel, buttonAction, buttonLabel2, buttonAction2, buttonLabel3, buttonAction3 } = props;
  const navigate = useNavigate();

  function handleClicked() {
    const path = window.location.pathname;
    const splittedPath = path.split('/');
    if (splittedPath[1] === 'admin') navigate('/admin');
    else navigate('/');
  }

  return (
    <div className="m-header">
      <button type="button" className="m-header__icon-title-button" onClick={handleClicked}>
        <Icon />
        <h1 className="m-header__title">{title}</h1>
      </button>
      {buttonAction && buttonLabel && !searchBar ? (
        <Button label={buttonLabel} type="primary" setClicked={buttonAction} />
      ) : null}

      {buttonAction2 && buttonLabel2 && !searchBar ? (
        <Button label={buttonLabel2} type="primary" setClicked={buttonAction2} />
      ) : null}

      {buttonAction3 && buttonLabel3 && !searchBar ? (
        <Button label={buttonLabel3} type="primary" setClicked={buttonAction3} />
      ) : null}

      {buttonAction && buttonLabel && searchBar ? (
        <div className="m-header__button-search">
          <Button label={buttonLabel} type="primary" setClicked={buttonAction} />
          {buttonAction2 && buttonLabel2 ? (
            <Button label={buttonLabel2} type="primary" setClicked={buttonAction2} />
          ) : null}
          {buttonAction3 && buttonLabel3 ? (
            <Button label={buttonLabel3} type="primary" setClicked={buttonAction3} />
          ) : null}
          <SearchBar />
        </div>
      ) : null}
      {searchBar && !buttonLabel && !buttonLabel2 ? <SearchBar /> : null}
    </div>
  );
}

Header.defaultProps = {
  searchBar: false,
  buttonLabel: '',
  buttonAction: null,
  buttonLabel2: '',
  buttonAction2: null,
  buttonLabel3: '',
  buttonAction3: null,
};

export default Header;
