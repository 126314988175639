import React from 'react';
import AdminDetailPageTemplate from '../../04_templates/AdminDetailPageTemplate/AdminDetailPageTemplate';

function AdminDetailPage() {
  return (
    <main>
      <AdminDetailPageTemplate />
    </main>
  );
}

export default AdminDetailPage;
