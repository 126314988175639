import React, { useEffect } from 'react';
import BookOverview from '../../../types/BookOverviewType';
import Header from '../../02_molecules/Header/Header';
import SubtitleBookOverview from '../../03_organisms/SubtitleBookOverview/SubtitleBookOverview';

interface adminOverviewProps {
  data?: BookOverview[];
}

function OverviewTemplate(props: adminOverviewProps) {
  const { data } = props;

  useEffect(() => {
    document.title = 'Sweet Bibi - Overzicht';
  }, []);

  return (
    <div>
      <header>
        <Header title="Sweet Bibi" searchBar />
      </header>
      <SubtitleBookOverview subtitle="Uitgeleend" books={data} />
      <SubtitleBookOverview subtitle="Beschikbaar" books={data} />
    </div>
  );
}

OverviewTemplate.defaultProps = {
  data: [],
};

export default OverviewTemplate;
