import React from 'react';
import AdminAddUserTemplate from '../../04_templates/AdminAddUserTemplate/AdminAddUserTemplate';

function AdminAddUserPage() {
    return (
        <main>
            <AdminAddUserTemplate />
        </main>
    );
}

export default AdminAddUserPage;