import React from 'react';

interface deleteButtonProps {
  id: number;
  title: string;
  author: string;
}

function DeleteButton(props: deleteButtonProps) {
  const { id, title, author } = props;

  function showPopUp() {
    const event = new CustomEvent('deleteClicked', {
      detail: {
        id,
        title,
        author,
      },
    });
    window.dispatchEvent(event);
  }

  return (
    <button type="button" className="a-delete-button" onClick={showPopUp}>
      <svg
        className="a-delete-button__svg"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.5 4V3C9.5 2.73478 9.60536 2.48043 9.79289 2.29289C9.98043 2.10536 10.2348 2 10.5 2H14.5C14.7652 2 15.0196 2.10536 15.2071 2.29289C15.3946 2.48043 15.5 2.73478 15.5 3V4H19.5C20.0304 4 20.5391 4.21071 20.9142 4.58579C21.2893 4.96086 21.5 5.46957 21.5 6V7C21.5 7.53043 21.2893 8.03914 20.9142 8.41421C20.5391 8.78929 20.0304 9 19.5 9H19.367L18.687 19.2C18.6363 19.9593 18.2989 20.6709 17.7432 21.1908C17.1875 21.7107 16.455 21.9999 15.694 22H9.326C8.56567 22 7.83372 21.7113 7.27811 21.1922C6.7225 20.6732 6.3847 19.9626 6.333 19.204L5.637 9H5.5C4.96957 9 4.46086 8.78929 4.08579 8.41421C3.71071 8.03914 3.5 7.53043 3.5 7V6C3.5 5.46957 3.71071 4.96086 4.08579 4.58579C4.46086 4.21071 4.96957 4 5.5 4H9.5ZM19.5 6H5.5V7H19.5V6ZM7.641 9L8.328 19.068C8.34524 19.3209 8.45789 19.5578 8.64316 19.7309C8.82843 19.9039 9.0725 20.0001 9.326 20H15.694C15.9478 20 16.1922 19.9036 16.3775 19.7301C16.5628 19.5567 16.6752 19.3193 16.692 19.066L17.362 9H7.642H7.641ZM10.5 10C10.7652 10 11.0196 10.1054 11.2071 10.2929C11.3946 10.4804 11.5 10.7348 11.5 11V18C11.5 18.2652 11.3946 18.5196 11.2071 18.7071C11.0196 18.8946 10.7652 19 10.5 19C10.2348 19 9.98043 18.8946 9.79289 18.7071C9.60536 18.5196 9.5 18.2652 9.5 18V11C9.5 10.7348 9.60536 10.4804 9.79289 10.2929C9.98043 10.1054 10.2348 10 10.5 10ZM14.5 10C14.7652 10 15.0196 10.1054 15.2071 10.2929C15.3946 10.4804 15.5 10.7348 15.5 11V18C15.5 18.2652 15.3946 18.5196 15.2071 18.7071C15.0196 18.8946 14.7652 19 14.5 19C14.2348 19 13.9804 18.8946 13.7929 18.7071C13.6054 18.5196 13.5 18.2652 13.5 18V11C13.5 10.7348 13.6054 10.4804 13.7929 10.2929C13.9804 10.1054 14.2348 10 14.5 10Z" />
      </svg>
      <span>Verwijderen</span>
    </button>
  );
}

export default DeleteButton;
