import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ReturnButton() {
  const location = useLocation();
  const navigate = useNavigate();

  function navigateBack() {
    const currentLocation = location.pathname;
    const route = currentLocation.substr(0, currentLocation.lastIndexOf('/') + 1);
    navigate(route);
  }

  return (
    <button type="button" className="a-return-button" onClick={navigateBack}>
      <svg
        className="a-return-button__svg"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM8.414 9H14C14.2652 9 14.5196 9.10536 14.7071 9.29289C14.8946 9.48043 15 9.73478 15 10C15 10.2652 14.8946 10.5196 14.7071 10.7071C14.5196 10.8946 14.2652 11 14 11H8.414L10.95 13.536C11.1322 13.7246 11.233 13.9772 11.2307 14.2394C11.2284 14.5016 11.1232 14.7524 10.9378 14.9378C10.7524 15.1232 10.5016 15.2284 10.2394 15.2307C9.9772 15.233 9.7246 15.1322 9.536 14.95L5.293 10.707C5.19982 10.6143 5.12587 10.5042 5.0754 10.3829C5.02494 10.2615 4.99897 10.1314 4.99897 10C4.99897 9.86859 5.02494 9.73848 5.0754 9.61715C5.12587 9.49581 5.19982 9.38565 5.293 9.293L9.536 5.05C9.7246 4.86784 9.9772 4.76705 10.2394 4.76933C10.5016 4.7716 10.7524 4.87677 10.9378 5.06218C11.1232 5.24759 11.2284 5.4984 11.2307 5.7606C11.233 6.0228 11.1322 6.2754 10.95 6.464L8.414 9Z"
          fill="black"
        />
      </svg>
    </button>
  );
}

export default ReturnButton;
