import React from 'react';
import { useNavigate } from 'react-router-dom';
import bookOverview from '../../../types/BookOverviewType';

interface editButtonProps {
  data: bookOverview;
}
function EditButton(props: editButtonProps) {
  const { data } = props;
  const navigate = useNavigate();

  function handleClicked(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    navigate('/admin/edit', { state: data });
  }

  return (
    <button type="button" className="a-edit-button" onClick={handleClicked}>
      <svg
        className="a-edit-button__svg"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.818 9.11005L15.282 5.57505L16.697 4.16105C17.327 3.53105 18.77 3.40605 19.525 4.16105L20.232 4.86805C20.987 5.62305 20.863 7.06605 20.232 7.69705L18.818 9.11005ZM17.404 10.5251L7.50402 20.4251L2.90802 21.4851L3.96802 16.8891L13.868 6.98905L17.404 10.5251Z"
          fill="#343434"
        />
      </svg>
    </button>
  );
}

export default EditButton;
