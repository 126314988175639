import React from 'react';
import AdminAddBookTemplate from '../../04_templates/AdminAddBookTemplate/AdminAddBookTemplate';

function AdminAddBookPage() {
  return (
    <main>
      <AdminAddBookTemplate />
    </main>
  );
}

export default AdminAddBookPage;
