import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../01_atoms/Button/Button';
import InputField from '../../01_atoms/InputField/InputField';
import Loader from '../../01_atoms/Loader/Loader';

interface popUpViewAddRfidProps {
  setShowPopUpRfid: (showPopupRfid: boolean) => void;
  navigateUrl?: string;
}

function PopUpViewAddRfid(props: popUpViewAddRfidProps) {
  const { setShowPopUpRfid, navigateUrl } = props;
  const navigate = useNavigate();

  const [rfidTagValue, setRfidTagValue] = useState('');
  const [clicked, setClicked] = useState('');
  const [scanning, setScanning] = useState(false);

  const deleteInputValue = () => {
    setRfidTagValue("");
    setScanning(true);
  }

  const inputChanged = (value: string) => {
    let rfidDecimal = "";
    let rfidHex = "";

    setRfidTagValue(value);
    rfidDecimal += value

    if (rfidDecimal.slice(-1) === "n") {
      rfidDecimal = rfidDecimal.slice(0, -1)
      rfidDecimal.split(" ").forEach(character => {
      if (character !== "") {
        if (character[0] === "0") rfidHex += "0";
        rfidHex += Number(character).toString(16);
        }
      })
      setRfidTagValue(rfidHex.toUpperCase());
      setScanning(false);
    }
  }

  const handleForm = (event: React.SyntheticEvent) => {
    // Issue - dankzij de alert "fill in field", is het mogelijk om voor de admin nog iets in te vullen in de inputfield.
    event.preventDefault();
    if (clicked === 'Toevoegen') {
      // TODO - Hier socket.io data doorgeven naar RfidInputfield input in AddBookForm
      // TODO - check if rfid has data .
      if (rfidTagValue) {
        if (navigateUrl !== '') navigate('/admin/add', { state: { data: rfidTagValue } });
        else {
          const e = new CustomEvent('RfidScanned', {
            detail: {
              rfid: rfidTagValue,
            },
          });
          window.dispatchEvent(e);
          setShowPopUpRfid(false);
        }
      }
    }
  };

  useEffect(() => {
    if (clicked === 'Annuleren') setShowPopUpRfid(false);
  }, [clicked]);

  return (
    <div className="o-pop-up-modal">
      <div className="o-pop-up-modal__container">
        <div className="o-pop-up-form__span-svg">
          <p>Voor het toevoegen van het boek, Scan eerst de RFID tag</p>

          <svg
            className="o-pop-up-form__svg"
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.625 18H9.25C9.45625 18 9.625 17.8313 9.625 17.625V9.375H17.875C18.0813 9.375 18.25 9.20625 18.25 9V6.375C18.25 6.16875 18.0813 6 17.875 6H9.4375C7.675 6 6.25 7.425 6.25 9.1875V17.625C6.25 17.8313 6.41875 18 6.625 18ZM30.625 9.375H38.875V17.625C38.875 17.8313 39.0438 18 39.25 18H41.875C42.0812 18 42.25 17.8313 42.25 17.625V9.1875C42.25 7.425 40.825 6 39.0625 6H30.625C30.4188 6 30.25 6.16875 30.25 6.375V9C30.25 9.20625 30.4188 9.375 30.625 9.375ZM17.875 38.625H9.625V30.375C9.625 30.1688 9.45625 30 9.25 30H6.625C6.41875 30 6.25 30.1688 6.25 30.375V38.8125C6.25 40.575 7.675 42 9.4375 42H17.875C18.0813 42 18.25 41.8312 18.25 41.625V39C18.25 38.7938 18.0813 38.625 17.875 38.625ZM41.875 30H39.25C39.0438 30 38.875 30.1688 38.875 30.375V38.625H30.625C30.4188 38.625 30.25 38.7938 30.25 39V41.625C30.25 41.8312 30.4188 42 30.625 42H39.0625C40.825 42 42.25 40.575 42.25 38.8125V30.375C42.25 30.1688 42.0812 30 41.875 30ZM42.625 22.3125H5.875C5.66875 22.3125 5.5 22.4813 5.5 22.6875V25.3125C5.5 25.5187 5.66875 25.6875 5.875 25.6875H42.625C42.8312 25.6875 43 25.5187 43 25.3125V22.6875C43 22.4813 42.8312 22.3125 42.625 22.3125Z"
              fill="black"
            />
          </svg>
        </div>

        <img
          src="https://www.nomadfoods.com/wp-content/uploads/2018/08/placeholder-1-e1533569576673-960x960.png"
          alt="Scanner"
          className="o-pop-up-modal__img"
        />
        <div className="o-pop-up-form__container">
          <form className="o-pop-up-form__rfid" id="pupup-rfid" onSubmit={handleForm}>
            <fieldset>
              <InputField
                labelName=""
                type="text"
                placeholder="RFID"
                value={rfidTagValue}
                onChange={inputChanged}
                isRequired
              />
            </fieldset>
          </form>
          {scanning ? (
            <Loader />
          ) : (
            <Button label="Scannen" type="primary-monochrome" setClicked={deleteInputValue} />
          )}
        </div>
        <div className="o-pop-up-modal__buttons">
          <Button
            label="Toevoegen"
            type="primary"
            submit
            submitForm="pupup-rfid"
            setClicked={setClicked}
          />
          <Button label="Annuleren" type="secondary" setClicked={setClicked} />
        </div>
      </div>
    </div>
  );
}

PopUpViewAddRfid.defaultProps = {
  navigateUrl: '',
};

export default PopUpViewAddRfid;
