import React from 'react';
import placeholder from '../../../resources/images/placeholder.png';

interface imageProps {
  url: string | undefined;
  title: string;
  status: string;
}

function BookImage(props: imageProps) {
  const { title, status, url } = props;

  return (
    <img
      className={
        status === 'Uitgeleend' ? 'a-book-image a-book-image__unavailable' : 'a-book-image'
      }
      src={url || placeholder}
      alt={`Boekomslag van ${title}`}
    />
  );
}

export default BookImage;
