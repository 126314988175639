import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Alert from './components/01_atoms/Alert/Alert';
import AdminAddBookPage from './components/05_pages/AdminAddBookPage/AdminAddBookPage';
import AdminAddUserPage from './components/05_pages/AdminAddUserPage/AdminAddUserPage';
import AdminDeleteUserPage from './components/05_pages/AdminDeleteUserPage/AdminDeleteUserPage';
import AdminDetailPage from './components/05_pages/AdminDetailPage/AdminDetailPage';
import AdminEditBookPage from './components/05_pages/AdminEditBookPage/AdminEditBookPage';
import AdminOverviewPage from './components/05_pages/AdminOverviewPage/AdminOverviewPage';
import DetailPage from './components/05_pages/DetailPage/DetailPage';
import ErrorPage from './components/05_pages/ErrorPage/ErrorPage';
import OverviewPage from './components/05_pages/OverviewPage/OverviewPage';
import { AlertType } from './types/AlertTypes';

function App() {
  // Alerts
  const [labelAlert, setLabelAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState<AlertType>('Success');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // Listen to alert event
    window.addEventListener('ShowAlert', (e) => {
      setLabelAlert((e as CustomEvent).detail.label);
      setTypeAlert((e as CustomEvent).detail.type);
      setShowAlert(true);
      const closeAlertAuto = setTimeout(() => setShowAlert(false), 5000);
      return () => clearTimeout(closeAlertAuto);
    });
    window.addEventListener('CloseAlert', (e) => {
      setShowAlert((e as CustomEvent).detail.showAlert);
    });

    return () => {
      // Remove event listeners
      window.removeEventListener('ShowAlert', (e) => {
        setLabelAlert((e as CustomEvent).detail.label);
        setTypeAlert((e as CustomEvent).detail.type);
      });
      window.removeEventListener('CloseAlert', (e) => {
        setShowAlert((e as CustomEvent).detail.showAlert);
      });
    };
  }, [showAlert]);
  return (
    <Router>
      {showAlert ? <Alert label={labelAlert} type={typeAlert} /> : null}
      <Routes>
        <Route path="/" element={<OverviewPage />} />
        <Route path="/detail" element={<DetailPage />} />
        <Route path="/admin" element={<AdminOverviewPage />} />
        <Route path="/admin/add" element={<AdminAddBookPage />} />
        <Route path="/admin/adduser" element={<AdminAddUserPage />} />
        <Route path="/admin/deleteuser" element={<AdminDeleteUserPage />} />
        <Route path="/admin/edit" element={<AdminEditBookPage />} />
        <Route path="/admin/detail" element={<AdminDetailPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
