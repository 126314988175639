import React from 'react';
import AdminDeleteUserTemplate from '../../04_templates/AdminDeleteUserTemplate/AdminDeleteUserTemplate';

function AdminAddUserPage() {
    return (
        <main>
            <AdminDeleteUserTemplate />
        </main>
    );
}

export default AdminAddUserPage;