import React from 'react';
import Button from '../../01_atoms/Button/Button';
import Header from '../../02_molecules/Header/Header';
import AddUserForm from '../../03_organisms/AddUserForm/AddUserForm';

function AdminAddUserTemplate() {
    return (
        <div>
            <header>
                <Header title="Sweet Bibi - Admin" />
            </header>
            <AddUserForm />
            <div className="t-admin-add-book__submit ">
                <Button label="Gebruiker toevoegen" type="primary" submit submitForm="add-book-form" setClicked={() => ''} />
                <Button
                    label="Annuleren"
                    type="secondary"
                    setClicked={() => {
                        window.location.href = '../admin';
                    }}
                />
            </div>
        </div>



    );
}

export default AdminAddUserTemplate;