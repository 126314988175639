import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Post, Put, Get } from '../../../constants/dataAccess';
import bookOverview from '../../../types/BookOverviewType';
import isbnType from '../../../types/IsbnType';
import userType from '../../../types/userType';
import InputField from '../../01_atoms/InputField/InputField';
import placeholder from '../../../resources/images/placeholder.png';

interface addBookFormProps {
  isbnData: isbnType | undefined;
  bookData?: bookOverview;
  editForm?: boolean;
  isbnNumber?: string | undefined;
  // rfidNumber?: string | undefined;
}

interface optionsType {
  value: string;
  label: string;
}

function AddBookForm(props: addBookFormProps) {
  const { isbnData, editForm, bookData, isbnNumber/* , rfidNumber */ } = props;
  const navigate = useNavigate();

  const [lockForm, setLockForm] = useState(false);

  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [owner, setOwner] = useState('');
  const [description, setDescription] = useState('');
  const [isAvailable, setIsAvailable] = useState('Beschikbaar');
  const [imgUrl, setImgUrl] = useState('');
  const [options, setOptions] = useState<optionsType[]>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loanedOutBy, setLoanedOutBy] = useState<any>('a87bbeb5-3702-4a1d-b8d7-fa4d7fdab72f');

  const getUsers = async () => {
    const optionsvalue: optionsType[] = [];
    const data: userType[] = await Get(`/users`);
    data.forEach((user: userType) => {
      optionsvalue.push({ value: user.accountId, label: user.displayName });
      if (isAvailable === 'Uitgeleend' && bookData?.currentLender === user.displayName)
        setLoanedOutBy({ value: user.accountId, label: user.displayName });
    });
    setOptions(optionsvalue);
  };


  useEffect(() => {
    if (bookData) {
      if (bookData.title) setTitle(bookData.title);
      if (bookData.author) setAuthor(bookData.author);
      if (bookData.owner) setOwner(bookData.owner);
      if (bookData.description) setDescription(bookData.description);
      if (bookData.available !== null) {
        if (bookData.available) setIsAvailable('Beschikbaar');
        else setIsAvailable('Uitgeleend');
      }
      if (bookData.coverBookUrl !== null) setImgUrl(bookData.coverBookUrl);
      else setImgUrl('');
    }
    if (isbnData) {
      if (isbnData.subtitle) setTitle(`${isbnData.title} - ${isbnData.subtitle}`);
      else setTitle(isbnData.title);

      if (isbnData.author) setAuthor(isbnData.author);
      if (isbnData.description) setDescription(isbnData.description);
      if (isbnData.coverBookUrl) setImgUrl(isbnData.coverBookUrl);
    }
    getUsers();
  }, [bookData, isbnData]);

  useEffect(() => {
    if (isAvailable === 'Uitgeleend') getUsers();
  }, [isAvailable]);

  const handleForm = async (event: React.SyntheticEvent) => {
    setLockForm(true);
    event.preventDefault();
    let available = true;
    if (isAvailable === 'Beschikbaar') available = true;
    else available = false;

    let boekData;

    if (available) {
      boekData = {
        title,
        subtitle: isbnData?.subtitle || title,
        description,
        available,
        author,
        owner,
        coverBookUrl: imgUrl,
        isbn: isbnNumber,
        rfidCode: null,
      };
    } else {
      boekData = {
        title,
        subtitle: isbnData?.subtitle || title,
        description,
        available,
        author,
        owner,
        coverBookUrl: imgUrl,
        isbn: isbnNumber,
        rfidCode: null,
        lenderAccountId: loanedOutBy.value,
      };
    }
    try {
      if (editForm) {
        const createBook = await Put(`/books/${bookData?.id}`, boekData);
        if (createBook.status === 400 || createBook.status === 500) {
          throw new Error();
        }
        navigate('/admin');
        const e = new CustomEvent('ShowAlert', {
          detail: {
            label: 'Boek succesvol aangepast',
            type: 'Success',
          },
        });
        window.dispatchEvent(e);
      } else {
        const createBook = await Post(`/books`, boekData);
        if (createBook.status === 400 || createBook.status === 500) {
          throw new Error();
        }
        navigate('/admin');
        const e = new CustomEvent('ShowAlert', {
          detail: {
            label: 'Boek succesvol toegevoegd',
            type: 'Success',
          },
        });
        window.dispatchEvent(e);
      }
    } catch (error) {
      if (editForm) {
        const e = new CustomEvent('ShowAlert', {
          detail: {
            label: 'Boek bewerken is niet gelukt',
            type: 'Warning',
          },
        });
        window.dispatchEvent(e);
      } else {
        const e = new CustomEvent('ShowAlert', {
          detail: {
            label: 'Boek toevoegen is niet gelukt',
            type: 'Warning',
          },
        });
        window.dispatchEvent(e);
      }
    }
    setLockForm(false);
  };

  return (
    <form id="add-book-form" onSubmit={handleForm}>
      <fieldset className="o-form" disabled={lockForm}>
        {/* Titel */}
        <div className="o-form-item__3-cols">
          <InputField
            labelName="Titel"
            type="text"
            placeholder="Titel"
            value={title}
            onChange={setTitle}
            isRequired
          />
        </div>

        {/* Auteur */}
        <div className="o-form-item__2-cols">
          <InputField
            labelName="Auteur"
            type="text"
            placeholder="Auteur"
            value={author}
            onChange={setAuthor}
            isRequired
          />
        </div>

        {/* Eigenaar */}
        <div className="o-form-item__2-cols">
          <InputField
            labelName="Eigenaar"
            type="text"
            placeholder="Eigenaar"
            value={owner}
            onChange={setOwner}
            isRequired
          />
        </div>

        {/* Korte inhoud */}
        <div className="o-form-item__2-cols o-form__summary">
          <InputField
            labelName="Korte inhoud"
            type="textarea"
            placeholder="Korte inhoud"
            value={description}
            onChange={setDescription}
            isRequired
          />
        </div>

        <img
          className={`o-form__cover ${imgUrl !== '' ? '' : 'o-form__cover-placeholder'}`}
          src={imgUrl !== '' ? imgUrl : placeholder}
          alt={`Boekomslag van ${title}`}
        />

        {/* Status */}
        <div className="o-form-item o-form-item__2-cols">
          <InputField
            labelName="Status"
            type="radio"
            value={isAvailable}
            onChange={setIsAvailable}
            radioValues={['Beschikbaar', 'Uitgeleend']}
            isRequired
          />
          {isAvailable === 'Uitgeleend' ? (
            <div className="o-form__radio-uitgeleend">
              <Select
                value={loanedOutBy}
                onChange={(option) => setLoanedOutBy({ value: option?.value, label: option.label })}
                options={options}
                className="o-form__select"
                classNamePrefix="o-form__select"
                placeholder="Naam"
              />
            </div>
          ) : null}
        </div>

        {/* Cover url */}
        <InputField
          labelName="Cover boek (url)"
          type="url"
          placeholder="img url"
          value={imgUrl}
          onChange={setImgUrl}
        />
      </fieldset>
    </form>
  );
}

AddBookForm.defaultProps = {
  bookData: null,
  editForm: false,
  isbnNumber: null,
  // rfidNumber: null,
};

export default AddBookForm;
