import React from 'react';

interface inputFieldProps {
  labelName: string;
  type: 'text' | 'textarea' | 'radio' | 'url';
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  isRequired?: boolean;
  radioValues?: string[];
}

function InputField(props: inputFieldProps) {
  const { labelName, type, placeholder, value, onChange, radioValues, isRequired } = props;
  return (
    <label htmlFor={labelName} className="a-input-field__container">
      {labelName}
      {type === 'text' || type === 'url' ? (
        <input
          className={`a-input-field ${value ? 'a-input-field__filled' : ''}`}
          id={labelName}
          name={labelName}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          required={isRequired}
        />
      ) : null}
      {type === 'textarea' ? (
        <textarea
          className={`a-input-field a-input-field__textarea ${
            value ? 'a-input-field__filled' : ''
          }`}
          id={labelName}
          name={labelName}
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      ) : null}
      {type === 'radio'
        ? radioValues?.map((radioValue: string) => (
            <label htmlFor={radioValue} key={radioValue}>
              <input
                className="a-input-field__radio"
                type="radio"
                name={labelName}
                id={radioValue}
                value={radioValue}
                onChange={(event) => onChange(event.target.value)}
                checked={radioValue === value}
              />
              {radioValue}
            </label>
          ))
        : null}
    </label>
  );
}

InputField.defaultProps = {
  radioValues: [],
  placeholder: '',
  isRequired: false,
};

export default InputField;
