import React from 'react';

interface labelProps {
  text: string;
  size: 'small' | 'medium';
}

function TextLabel(props: labelProps) {
  const { text, size } = props;
  let className = '';

  switch (size) {
    case 'small':
      className = 'a-text-label__small a-text-label--bold';
      break;
    case 'medium':
      className = 'a-text-label__medium';
      break;
    default:
      break;
  }
  return <p className={className}>{text}</p>;
}

export default TextLabel;
