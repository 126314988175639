import React from 'react';

function Loader() {
  return (
    <div className="a-loader">
      <div className="a-loader__dot-1" />
      <div className="a-loader__dot-2" />
      <div className="a-loader__dot-3" />
    </div>
  );
}

export default Loader;
