import React from 'react';

function Icon() {
  return (
    <svg
      className="a-icon"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_193_388)">
        <circle cx="26" cy="26" r="24" fill="#FAD018" />
      </g>
      <path
        d="M13 22.875H18.2C21.0719 22.875 23.4 25.2535 23.4 28.1875V33.5H18.2C15.3281 33.5 13 31.1215 13 28.1875V22.875Z"
        fill="white"
      />
      <path
        d="M24.3176 26C24.3176 21.8579 27.6044 18.5 31.6588 18.5H39V26C39 30.1421 35.7132 33.5 31.6588 33.5H24.3176V26Z"
        fill="#343434"
      />
      <defs>
        <filter
          id="filter0_d_193_388"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorinterpolation-filters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_193_388"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_193_388"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
