import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import bookOverview from '../../../types/BookOverviewType';
import IsbnType from '../../../types/IsbnType';
import Button from '../../01_atoms/Button/Button';
import DeleteButton from '../../01_atoms/DeleteButton/DeleteButton';
import Subtitle from '../../01_atoms/Subtitle/Subtitle';
import Header from '../../02_molecules/Header/Header';
import IsbnSearch from '../../02_molecules/IsbnSearch/IsbnSearch';
// import RfidInput from '../../02_molecules/RfidInput/RfidInput';
import AddBookForm from '../../03_organisms/AddBookForm/AddBookForm';
import PopUpViewAddRfid from '../../03_organisms/PopUpViewAddRfid/PopUpViewAddRfid';
import PopUpViewDelete from '../../03_organisms/PopUpViewDelete/PopUpViewDelete';

function adminEditBookTemplate() {
  const location = useLocation();
  const data = location.state as bookOverview;
  const subtitle = `Bewerk ${data.title}`;

  // ISBN
  const [isbnData, setIsbnData] = useState<IsbnType>();
  const [isbn, setIsbn] = useState('');

  // RFID
  const [showPopupRfid, setShowPopUpRfid] = useState(false);
  const [rfid, setRfid] = useState<string>(data.rfidCode);

  // Delete
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [id, setId] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);

  // const showPopupRfidFunction = () => {
  //   setShowPopUpRfid(true);
  // };

  useEffect(() => {
    document.title = 'Sweet Bibi - Bewerk Boek';
    window.addEventListener('deleteClicked', (e) => {
      setTitle((e as CustomEvent).detail.title);
      setAuthor((e as CustomEvent).detail.author);
      setId((e as CustomEvent).detail.id);

      setShowPopUp(!showPopUp);
    });

    window.addEventListener('RfidScanned', (e) => {
      setRfid((e as CustomEvent).detail.rfid);
    });

    if (data.isbn) setIsbn(data.isbn.toString());
    return () => {
      // Remove event listeners
      window.removeEventListener('deleteClicked', (e) => {
        setTitle((e as CustomEvent).detail.title);
        setAuthor((e as CustomEvent).detail.author);
        setId((e as CustomEvent).detail.id);
      });
      window.removeEventListener('RfidScanned', (e) => {
        setRfid((e as CustomEvent).detail.rfid);
      });
    };
  }, [showPopUp, rfid]);
  return (
    <div>
      {showPopUp ? (
        <PopUpViewDelete title={title} auteur={author} id={id} setShowPopUp={setShowPopUp} />
      ) : null}
      {showPopupRfid ? <PopUpViewAddRfid setShowPopUpRfid={setShowPopUpRfid} /> : null}
      <header>
        <Header title="Sweet Bibi - Admin" />
      </header>
      <Subtitle title={subtitle} />
      <div className="t-admin-edit-book__isbn-rfid-container">
        {/* <RfidInput rfidValue={rfid} setRfidPopButton={showPopupRfidFunction} /> */}
        <IsbnSearch setData={setIsbnData} setIsbnNumber={setIsbn} isbnValue={isbn} />
      </div>
      <AddBookForm
        isbnData={isbnData}
        bookData={data}
        isbnNumber={isbn}
        // rfidNumber={rfid}
        editForm
      />
      <div className="t-admin-edit-book__submit">
        <div className="t-admin-edit-book__container">
          <Button
            label="Boek Bewerken"
            type="primary"
            submit
            submitForm="add-book-form"
            setClicked={() => ''}
          />
          <Button
            label="Annuleren"
            type="secondary"
            setClicked={() => {
              window.location.href = '../admin';
            }}
          />
        </div>
        <DeleteButton id={data.id} title={data.title} author={data.author} />

      </div>
    </div>
  );
}

export default adminEditBookTemplate;
