import React from 'react';
import DetailPageTemplate from '../../04_templates/DetailPageTemplate/DetailPageTemplate';

function DetailPage() {
  return (
    <main>
      <DetailPageTemplate />
    </main>
  );
}

export default DetailPage;
