import React, { useState } from 'react';
import IsbnType from '../../../types/IsbnType';
import Button from '../../01_atoms/Button/Button';
import Subtitle from '../../01_atoms/Subtitle/Subtitle';
import Header from '../../02_molecules/Header/Header';
import IsbnSearch from '../../02_molecules/IsbnSearch/IsbnSearch';
import AddBookForm from '../../03_organisms/AddBookForm/AddBookForm';

function AdminAddBookTemplate() {
  const [isbnData, setIsbnData] = useState<IsbnType>();
  const [isbn, setIsbn] = useState('');


  return (
    <div>
      <header>
        <Header title="Sweet Bibi - Admin" />
      </header>
      <Subtitle title="Voeg een nieuw boek toe" />
      <div className="t-admin-add-book__isbn-rfid-container">
        <IsbnSearch setData={setIsbnData} setIsbnNumber={setIsbn} />
      </div>
      <AddBookForm isbnData={isbnData} isbnNumber={isbn} />
      <div className="t-admin-add-book__submit ">
        <Button label="Ja" type="primary" submit submitForm="add-book-form" setClicked={() => ''} />
        <Button
          label="Annuleren"
          type="secondary"
          setClicked={() => {
            window.location.href = '../admin';
          }}
        />
      </div>
    </div>
  );
}

export default AdminAddBookTemplate;
