import React, { useEffect, useState } from 'react';
import BookOverview from '../../../types/BookOverviewType';
import Subtitle from '../../01_atoms/Subtitle/Subtitle';
import BookOverviewItem from '../../02_molecules/BookOverviewItem/BookOverviewItem';

interface bookOverviewProps {
  subtitle: string;
  books?: BookOverview[];
  admin?: boolean;
}

function SubtitleBookOverview(props: bookOverviewProps) {
  const { subtitle, books, admin } = props; // Subtitle for subtitle component
  const [filter, setFilter] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  let status = true;
  let totalBooksVisible = 0;

  if (subtitle === 'Uitgeleend') {
    status = false; // Set boolean for if statement render
  }
  function compare(a: BookOverview, b: BookOverview) {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  }

  if (books) books.sort(compare);

  useEffect(() => {
    // Listen to the filter event
    window.addEventListener('filter', (e) => {
      const value = (e as CustomEvent).detail;
      setFilter(value);
    });
    // Check if subtitle is clicked (colapse books)
    window.addEventListener(subtitle, (e) => {
      const value = (e as CustomEvent).detail;
      setIsVisible(value);
    });
    return () => {
      window.removeEventListener('filter', (e) => {
        const value = (e as CustomEvent).detail;
        setFilter(value);
      });
      window.removeEventListener(subtitle, (e) => {
        const value = (e as CustomEvent).detail;
        setIsVisible(value);
      });
    };
  }, []);

  return (
    <section>
      <Subtitle title={subtitle} button />
      {isVisible ? (
        <div className="o-subtitle-book-overview">
          {books
            ?.filter((book) => {
              if (filter === '') {
                return book;
              }
              if (
                book.title.toLowerCase().includes(filter.toLowerCase()) ||
                book.author.toLowerCase().includes(filter.toLowerCase())
              ) {
                return book;
              }
              return null;
            })
            ?.map((book: BookOverview) => {
              if (book.available === status) {
                totalBooksVisible += 1;
                // if statement if book is loaned out fill loanedBy name in
                let loanedBy;
                if (status === false) {
                  loanedBy = book.currentLender;
                }
                if (admin) {
                  return (
                    <BookOverviewItem
                      key={book.id}
                      status={subtitle}
                      data={book}
                      displayName={loanedBy}
                      admin
                    />
                  );
                }
                return (
                  <BookOverviewItem
                    key={book.id}
                    status={subtitle}
                    data={book}
                    displayName={loanedBy}
                  />
                );
              }
              return null;
            })}
          {totalBooksVisible === 0 && filter !== '' ? (
            <p className="o-subtible-book__not-found"> Geen boeken gevonden voor jouw zoekterm </p>
          ) : null}
          {totalBooksVisible === 0 && filter === '' && !status ? (
            <p className="o-subtible-book__not-found"> Er zijn geen uitgeleende boeken </p>
          ) : null}
          {totalBooksVisible === 0 && filter === '' && status ? (
            <p className="o-subtible-book__not-found"> Er zijn geen beschikbare boeken </p>
          ) : null}
        </div>
      ) : (
        <div className="o-subtitle-book-overview" />
      )}
    </section>
  );
}

SubtitleBookOverview.defaultProps = {
  books: [],
  admin: false,
};

export default SubtitleBookOverview;
