import React, { useEffect, useState } from 'react';

interface subtitleProps {
  title: string;
  button?: boolean;
}

function subtitle(props: subtitleProps) {
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const event = new CustomEvent(props.title, { detail: visible });
    window.dispatchEvent(event);
  }, [visible]);
  return (
    <div>
      {props.button ? (
        <button
          type="button"
          className="a-subtitle a-subtitle__button"
          onClick={() => {
            setVisible(!visible);
          }}
        >
          <h1 className="a-subtitle__text">{props.title}</h1>
          <svg
            className={visible ? '' : 'a-subtitle__icon'}
            width="1.5rem"
            height="1.5rem"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.293 14.707C9.38565 14.8002 9.49581 14.8741 9.61715 14.9246C9.73848 14.9751 9.86859 15.001 10 15.001C10.1314 15.001 10.2615 14.9751 10.3829 14.9246C10.5042 14.8741 10.6143 14.8002 10.707 14.707L14.95 10.464C15.0455 10.3718 15.1217 10.2614 15.1741 10.1394C15.2265 10.0174 15.2541 9.88618 15.2552 9.7534C15.2564 9.62062 15.2311 9.48894 15.1808 9.36605C15.1305 9.24315 15.0563 9.1315 14.9624 9.03761C14.8685 8.94371 14.7569 8.86946 14.634 8.81918C14.5111 8.7689 14.3794 8.7436 14.2466 8.74475C14.1138 8.7459 13.9826 8.77349 13.8606 8.8259C13.7386 8.87831 13.6282 8.95449 13.536 9.05L11 11.586V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73478 5 9.48043 5.10536 9.29289 5.29289C9.10536 5.48043 9 5.73478 9 6V11.586L6.464 9.05C6.37175 8.95449 6.26141 8.87831 6.1394 8.8259C6.0174 8.77349 5.88618 8.7459 5.7534 8.74475C5.62062 8.7436 5.48894 8.7689 5.36605 8.81918C5.24315 8.86946 5.1315 8.94371 5.0376 9.03761C4.94371 9.1315 4.86946 9.24315 4.81918 9.36605C4.7689 9.48894 4.7436 9.62062 4.74475 9.7534C4.7459 9.88618 4.77349 10.0174 4.8259 10.1394C4.87831 10.2614 4.95449 10.3718 5.05 10.464L9.293 14.707V14.707ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20Z"
              fill="black"
            />
          </svg>
        </button>
      ) : (
        <div className="a-subtitle">
          <h1 className="a-subtitle__text">{props.title}</h1>
        </div>
      )}
    </div>
  );
}

export default subtitle;
