import React from 'react';
import { useNavigate } from 'react-router-dom';
import bookOverview from '../../../types/BookOverviewType';
import BookImage from '../../01_atoms/BookImage/BookImage';
import EditButton from '../../01_atoms/EditButton/EditButton';
import ReaderBanner from '../../01_atoms/ReaderBanner/ReaderBanner';
import TextLabel from '../../01_atoms/TextLabel/TextLabel';

interface bookOverviewItemProps {
  status: string;
  data: bookOverview;
  admin?: boolean;
  displayName?: string | null;
}

function bookOverviewItem(props: bookOverviewItemProps) {
  const { status, data, admin, displayName } = props;
  const navigate = useNavigate();

  return (
    <article className="m-book-overview-item__container">
      {admin ? (
        <button
          type="button"
          className="m-book-overview-button"
          onClick={() => navigate('/admin/detail', { state: data })}
        >
          <div className="m-book-overview-item__image">
            <EditButton data={data} />
            <BookImage title={data.title} status={status} url={data.coverBookUrl} />
            {displayName && displayName !== '' ? <ReaderBanner displayName={displayName} /> : null}
          </div>
          <TextLabel text={data.title} size="medium" />
          <TextLabel text={data.author} size="small" />
        </button>
      ) : (
        <button
          type="button"
          className="m-book-overview-button"
          onClick={() => navigate('/detail', { state: data })}
        >
          <div className="m-book-overview-item__image">
            <BookImage title={data.title} status={status} url={data.coverBookUrl} />
            {displayName ? <ReaderBanner displayName={displayName} /> : null}
          </div>
          <TextLabel text={data.title} size="medium" />
          <TextLabel text={data.author} size="small" />
        </button>
      )}
    </article>
  );
}

export default bookOverviewItem;
