import React from 'react';

function ErrorPage() {
  return (
    <div>
      <p>Error 404 Page does not exist</p>
    </div>
  );
}

export default ErrorPage;
