import React from 'react';
import { useLocation } from 'react-router-dom';
import bookOverview from '../../../types/BookOverviewType';
import Subtitle from '../../01_atoms/Subtitle/Subtitle';
import Header from '../../02_molecules/Header/Header';
import ReturnIsbn from '../../02_molecules/ReturnIsbn/ReturnIsbn';
import BookDetails from '../../03_organisms/BookDetails/BookDetails';

function DetailPage() {
  const location = useLocation();
  const data = location.state as bookOverview;

  let isbn = '';
  if (data.isbn) isbn = data.isbn.toString();

  return (
    <div>
      <header>
        <Header title="Sweet Bibi" />
      </header>
      <Subtitle title={data.title} />
      <ReturnIsbn isbn={isbn} />
      <BookDetails data={data} />
    </div>
  );
}

export default DetailPage;
