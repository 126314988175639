import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Get, Post } from '../../../constants/dataAccess';
import InputField from '../../01_atoms/InputField/InputField';

function AddUserForm() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mail, setMail] = useState('');
    const [lockForm, setLockForm] = useState(false);

    const navigate = useNavigate();

    const handleForm = async (event: React.SyntheticEvent) => {
        setLockForm(true);
        event.preventDefault();

        try {
            const user = {
                accountId: uuidv4(),
                displayName: `${firstName} ${lastName}`,
                mail,
                userPrincipalName: 'tempvalue',
            };
            try {
                const existingUser = await Get(`/users/${user.accountId}`);
                if (!existingUser.id) {
                    const createUser = await Post('/users', user);
                    if (createUser.status === 400 || createUser.status === 500 || createUser.message) {
                        throw new Error();
                    }
                    navigate('/admin');
                    const e = new CustomEvent('ShowAlert', {
                        detail: {
                            label: 'Gebruiker succesvol toegevoegd',
                            type: 'Success',
                        },
                    });
                    window.dispatchEvent(e);
                } else {
                    const e = new CustomEvent('ShowAlert', {
                        detail: {
                            label: 'Gebruiker bestaat reeds.',
                            type: 'Warning',
                        },
                    });
                    window.dispatchEvent(e);
                }
            } catch (error) {
                const e = new CustomEvent('ShowAlert', {
                    detail: {
                        label: 'Gebruiker toevoegen is niet gelukt',
                        type: 'Warning',
                    },
                });
                window.dispatchEvent(e);
            }
        } catch (error) {
            const e = new CustomEvent('ShowAlert', {
                detail: {
                    label: 'Error during operation.',
                    type: 'Warning',
                },
            });
            window.dispatchEvent(e);
        }
        setLockForm(false);
    };

    return (
        <form id="add-book-form" onSubmit={handleForm}>
            <fieldset className="o-form" disabled={lockForm}>
                {/* Voornaam */}
                <div className="o-form-item__3-cols">
                    <InputField
                        labelName="Voornaam"
                        type="text"
                        placeholder="voornaam"
                        value={firstName}
                        onChange={setFirstName}
                        isRequired
                    />
                </div>
                {/* Familienaam */}
                <div className="o-form-item__3-cols">
                    <InputField
                        labelName="Familienaam"
                        type="text"
                        placeholder="familienaam"
                        value={lastName}
                        onChange={setLastName}
                        isRequired
                    />
                </div>
                {/* Mail */}
                <div className="o-form-item__3-cols">
                    <InputField
                        labelName="E-mail"
                        type="text"
                        placeholder="mail"
                        value={mail}
                        onChange={setMail}
                        isRequired
                    />
                </div>
            </fieldset>
        </form>
    )
}

export default AddUserForm;